<template>
  <v-container fluid class="my-4">

    <v-card elevation="4">
      <v-card-text>

        <v-row align="center" justify="space-between">
          <v-col>
            <h1 class="text-h6 font-weight-bold text--primary ml-2">Lista de Inscrições</h1>
          </v-col>
          <v-col class="text-right">

            <v-btn
              icon
              :color="showFilters ? `primary` : `default`"
              @click="toggleFilters"
              :title="showFilters ? `Esconder filtros` : `Mostrar filtros`"
            >
              <v-icon>mdi-filter-outline</v-icon>
            </v-btn>

            <v-btn icon color="primary" @click="downloadCsv" title="Download CSV">
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>

          </v-col>
        </v-row>

        <v-row class="filters-holder" :class="{ show: showFilters }">
          <v-col>
            <div class="bg rounded-lg grey lighten-4 px-4 pb-2 pt-2">
              
              <div class="mb-4">
                <v-simple-table class="filters-table mb-4">
                  <template v-slot:default>
                    <tbody>
                      <tr v-for="(filterRow, i) in filters" :key="i">
                        <td style="width:210px">
                          <v-select
                            v-model="filterRow.field"
                            :items="filterFieldsItems"
                            label="Campo"
                            @change="updateFilterFieldsItems(filterRow)"
                          ></v-select>
                        </td>
                        <td>
                          <v-text-field
                            v-if="filterRow.type == null || filterRow.type == 'text'"
                            v-model="filterRow.value"
                            label="Termo"
                            @input="filterTermUpdated"
                            :disabled="filterRow.type == null"
                          ></v-text-field>
                          <div v-if="filterRow.type == 'checkboxes' && filterRow.options">
                            <v-checkbox
                              class="inline mr-5 mt-0"
                              v-for="option in filterRow.options"
                              v-model="filterRow.value"
                              :key="`${filterRow.field}-${option.id}`"
                              :label="option.description"
                              :value="option.id"
                              @change="filterTermUpdated"
                              hide-details
                              multiple
                              dense
                            ></v-checkbox>
                          </div>
                        </td>
                        <td style="width:72px;" class="text-right">
                          <v-btn color="primary" icon @click="removeFilterField(i)">
                            <v-icon >mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <v-btn color="primary" small @click="addFilterField">
                  <span v-if="filters.length > 0">
                    <v-icon small>mdi-plus</v-icon>
                    Adicionar campo
                  </span>
                  <span v-else>
                    <v-icon small>mdi-magnify</v-icon>
                    Pesquisar por campo
                  </span>
                </v-btn>
              
              </div>

              <v-divider></v-divider>

              <v-switch
                v-model="options.lastUnique"
                label="Apenas o último registo de cada clínico"
              ></v-switch>

            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="items"
              :options.sync="options"
              :loading="loading"
              :server-items-length="totalItems"
              loading-text="A carregar..."
            >
              <template v-slot:item.is_new="{ item }">
                <span>{{ item.is_new ? `Nova` : `Actualização` }}</span>
              </template>
              <template v-slot:item.actions="{ item }">
                <span>
                  <v-btn class="btn-action" color="primary" icon title="Ver detalhes" @click="registrationDetails = item; showModalDetails = true;">
                    <v-icon>mdi-eye-plus</v-icon>
                  </v-btn>
                  <v-btn class="btn-action" color="red" icon title="Eliminar registo" @click="promptDelete(item.id)">
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>

    <v-dialog
      v-model="showModalDetails"
      max-width="800"
    >
      <v-card>
        <v-card-text>
          <div class="pt-6">
            <div class="text-h6 text--primary">Funções que desempenha:</div>
            <ul class="mb-2">
              <li v-for="role in registrationDetails.roles" :key="`role-${role.id}`">
                {{ role.description }}
              </li>
            </ul>
            <div class="text-h6 text--primary">Participação Presente:</div>
            <ul class="mb-2">
              <li v-for="presPart in registrationDetails.present_participations" :key="`present-${presPart.id}`">
                {{ presPart.description }}<span v-if="presPart.id == `1`">: {{ presPart.response }}</span>
              </li>
            </ul>
            <div class="text-h6 text--primary">Participação Futura:</div>
            <ul class="mb-2">
              <li v-for="futurePart in registrationDetails.future_participations" :key="`future-${futurePart.id}`">
                {{ futurePart.description }}<span v-if="futurePart.id == `1`">: {{ futurePart.other_description }}</span>
              </li>
            </ul>
            <div class="text-h6 text--primary">Áreas de Interesse:</div>
            <ul class="mb-2">
              <li v-for="areas in registrationDetails.areas_of_interest" :key="`areas-${areas.id}`">
                {{ areas.description }}<span v-if="areas.id == `1`">: {{ areas.other_description }}</span>
              </li>
            </ul>
            <div class="text-h6 text--primary">Participação em Grupos de Interesse do GRESP:</div>
            <ul class="mb-2">
              <li v-for="workGroup in registrationDetails.work_groups_participations" :key="`work-groups-${workGroup.id}`">
                <div class="d-flex flex-row justify-space-between">
                  <span>{{ workGroup.description }}</span><span class="black--text font-weight-medium">{{ workGroupsChoices[workGroup.value] }}</span>
                </div>
              </li>
            </ul>
          </div>
          <!-- <small><pre>{{registrationDetails}}</pre></small> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="showModalDetails = false; registrationDetails = {};">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showModalDelete"
      persistent
      max-width="480"
    >
      <v-card>
        <v-card-title>
          Tem a certeza que quer eliminar este registo?
        </v-card-title>
        <v-card-text>
          <div class="text-weight-medium text--primary">
            Digite a palavra "<span class="red--text">ELIMINAR</span>" para confirmar.
          </div>
          <v-text-field v-model="registrationDeleteConfirmation" autofocus></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="red" :disabled="registrationDeleteConfirmation.toUpperCase() != 'ELIMINAR'" @click="deleteRegistration">
            Eliminar
          </v-btn>
          <v-btn color="primary" @click="closePromptDelete">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: 'AdminHome',
  data: () => ({
    loading: true,
    workGroupsChoices: {
      member: "Já sou membro",
      interested: "Estou interessado",
      uninterested: "Não estou interessado",
    },
    rolesList: [],
    workAreasList: [],
    areasOfInterestList: [],
    showModalDetails: false,
    registrationDetails: {},
    showModalDelete: false,
    registrationToDelete: null,
    registrationDeleteConfirmation: "",
    showFilters: false,
    blankFilter: { field: null, value: null, type: null },
    filters: [],
    filterFieldsItems: [
      // { text: 'Criado a', value: 'created_at' },
      // { text: 'Tipo', value: 'is_new' },
      { text: 'Nome', value: 'name', disabled: false, type: 'text', },
      { text: 'Email', value: 'email', disabled: false, type: 'text', },
      { text: 'Nº Sóc. APMGF', value: 'apmgf_num', disabled: false, type: 'text', },
      { text: 'Local de Trabalho', value: 'work_location', disabled: false, type: 'text', },
      { text: 'Zona de Trabalho', value: 'work_area_id', disabled: false, type: 'checkboxes', options: [] },
      { text: 'Áreas de Interesse', value: 'area_of_interest_id', disabled: false, type: 'checkboxes', options: [] },
      { text: 'Funções que desempenha', value: 'role_id', disabled: false, type: 'checkboxes', options: [] },
    ],
    options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: [ 'created_at' ],
      sortDesc: [ true ],
      lastUnique: false,
    },
    headers: [
      { text: 'Criado a', value: 'created_at', align: 'center', width: 176 },
      { text: 'Tipo', value: 'is_new', align: 'center', sortable: false },
      { text: 'Nome', value: 'name', align: 'left' },
      { text: 'Email', value: 'email', align: 'left' },
      { text: 'Nº Sóc. APMGF', value: 'apmgf_num', align: 'right' },
      { text: 'Local de Trabalho', value: 'work_location', align: 'center' },
      { text: 'Zona de Trabalho', value: 'work_area.description', align: 'center', sortable: true },
      { text: '', value: 'actions', align: 'center', sortable: false, width: 104 },
    ],
    items: [],
    totalItems: 0,
  }),
  watch: {
    options: {
      deep: true,
      handler() {
        // console.log('options has changed!', this.options);
        this.fetchData();
      }
    }
  },
  computed: {
    optionsUri() {

      const pagination = this.options.itemsPerPage >= 0
        ? `&page=${this.options.page}&perPage=${this.options.itemsPerPage}` 
        : `&page=1&perPage=all`;

      const order = `&orderBy=${this.options.sortBy[0]}&order=${this.options.sortDesc[0]? "desc" : "asc"}`;

      console.log(order);

      const lastUnique = this.options.lastUnique ? `&lastUnique` : ``;

      let filterFields = ``;
      let filterTerms = ``
      this.filters.forEach(item => {
        const sep = filterFields.length <=0 ? `` : `,`;
        filterFields += `${sep}${item.field}`;
        if(item.type == 'checkboxes')
          filterTerms += `${sep}${item.value.join('|')}`;
        else if (item.type == 'text')  
          filterTerms += `${sep}${encodeURIComponent(item.value)}`; 
      });
      const filters = this.filters.length <= 0
        ? ``
        : `&filterFields=${filterFields}&filterTerms=${filterTerms}`;

      return `${pagination}${order}${lastUnique}${filters}`;
    },

  },
  methods: {
    fetchData() {
      this.loading = true;
      const apiEndpoint = `${process.env.VUE_APP_API_URL}?action=listRegistrations&token=${this.$root.token}${this.optionsUri}`;
      axios.get(apiEndpoint)
      .then(resp => resp.data)
      .then(data => {
        // console.log("fetchData():", data);
        this.items = data.items;
        this.totalItems = data.totalItems;

        // work areas
        let workAreasFilterField = this.filterFieldsItems.filter(filterField => filterField.value === 'work_area_id')[0];
        this.workAreasList = workAreasFilterField.options = data.workAreasList;

        // areas of interest
        let areasOfInterestFilterField = this.filterFieldsItems.filter(filterField => filterField.value === 'area_of_interest_id')[0];
        this.areasOfInterestList = areasOfInterestFilterField.options = data.areasOfInterestList;

        // roles
        let rolesFilterField = this.filterFieldsItems.filter(filterField => filterField.value === 'role_id')[0];
        this.rolesList = rolesFilterField.options = data.rolesList;

      })
      .catch(err => {
        if( 
          err.response?.data?.errors?.includes("Invalid token") ||
          err.response?.data?.errors?.includes("Token expired")
        ) {
          localStorage.removeItem("grespRegistrations");
          delete this.$root.token;
          this.$router.push({ name: "AdminLogin"});
        } else {
          console.error(err);
          console.error(err.response.data);
        }
      })
      .finally(() => {
        this.loading = false;
      });
    },
    downloadCsv() {
      window.location = `${process.env.VUE_APP_API_URL}?action=getRegistrationsCSV&token=${this.$root.token}${this.optionsUri}`;
    },
    toggleFilters() {
      if(this.showFilters) {
        this.showFilters = false;
        setTimeout(() => {
          this.filters = [];
          this.options.lastUnique = false;
          this.updateFilterFieldsItems();
          this.fetchData();
        }, 350);
      } else {
        this.showFilters = true;
      }
    },
    updateFilterFieldsItems(filter) {
      filter.value = null;
      this.filterFieldsItems.forEach(field => {
        const idx = this.filters.findIndex(item => field.value === item.field);
        if(idx < 0) field.disabled = false;
        else field.disabled = true;
        if(field.value === filter.field) {
          filter.type = field.type;
          filter.options = field.options;
        };
      });
    },
    addFilterField() {
      if(this.filters.length < this.filterFieldsItems.length) {
        this.filters.push(JSON.parse(JSON.stringify(this.blankFilter)));
      }
    },
    removeFilterField(idx) {
      let filterField = this.filterFieldsItems.filter(item => item.value === this.filters[idx].field)[0];
      filterField.disabled = false;
      this.filters.splice(idx,1);
      this.fetchData();
    },
    filterTermUpdated() {
      this.options.page = 1;
      this.fetchData();
    },
    promptDelete(id) {
      this.registrationToDelete = id;
      this.showModalDelete = true;
    },
    closePromptDelete(id) {
      this.registrationToDelete = null;
      this.registrationDeleteConfirmation = "";
      this.showModalDelete = false;
    },
    deleteRegistration() {
      const apiEndpoint = `${process.env.VUE_APP_API_URL}?action=deleteRegistration&token=${this.$root.token}&id=${this.registrationToDelete}`;
      axios.delete(apiEndpoint)
      .then(() => {
        setTimeout(() => {
          this.fetchData();
        }, 50);
      })
      .catch(err => {
        if( 
          err.response?.data?.errors?.includes("Invalid token") ||
          err.response?.data?.errors?.includes("Token expired")
        ) {
          localStorage.removeItem("grespRegistrations");
          delete this.$root.token;
          this.$router.push({ name: "AdminLogin"});
        } else {
          console.error(err);
          console.error(err.response.data);
        }
      })
      .finally(() => {
        this.closePromptDelete();
      });
    },
  },
  created() {
    this.fetchData();
  }
}

</script>

<style lang="scss" scoped>
.filters-holder {
  transition: all 300ms linear;
  box-sizing: border-box;
  overflow: hidden;
  max-height: 0px;

  &.show {
    max-height: 500px;
  }

  .filters-table {
    background-color: transparent;
    tr {


      &:hover {
        background-color: transparent !important;

      }

      td {
        border-bottom: 0px !important;
      }
    }
  }
}

.v-data-table {
  tbody {
    tr {
      .btn-action {
        display: none;
      }
      &:hover {
        .btn-action {
          display: inline-block;
        }
      }
    }
  }
}

.inline {
  display: inline-block;
}
</style>